import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Flag } from 'lucide-react';

function Header() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? 'text-blue-600 font-semibold' : 'text-gray-600 hover:text-gray-900';
  };

  return (
    <header className="bg-white shadow-sm">
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-4">
            <Flag className="h-6 w-6 text-blue-600" />
            <h1 className="text-2xl font-bold text-gray-900">Envirology</h1>
          </Link>
          <nav className="hidden md:flex space-x-8">
            <Link to="/" className={isActive('/')}>
              Home
            </Link>
            <Link to="/about" className={isActive('/about')}>
              About
            </Link>
            <Link to="/submit" className={isActive('/submit')}>
              Submit
            </Link>
            <Link to="/browse" className={isActive('/browse')}>
              Issues
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;