import React, { useState } from 'react';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { Label } from "../components/ui/label";

function Submit() {
  const [formData, setFormData] = useState({
    title: '',
    authors: '',
    abstract: '',
    keywords: '',
    manuscript: null
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      manuscript: e.target.files[0]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      //const response = await fetch('http://localhost:5000/submit', {
        const response = await fetch('http://flask-env-final-rr.eba-pbrmzcv3.us-west-2.elasticbeanstalk.com/submit', {
        
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setSubmitStatus({ type: 'success', message: 'Research submitted successfully!' });
      
      // Reset form after successful submission
      setFormData({
        title: '',
        authors: '',
        abstract: '',
        keywords: '',
        manuscript: null
      });
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus({ type: 'error', message: 'Failed to submit research. Please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-900">Submit Your Research</h2>
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto space-y-6">
        <div>
          <Label htmlFor="title">Title</Label>
          <Input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <Label htmlFor="authors">Authors</Label>
          <Input
            type="text"
            id="authors"
            name="authors"
            value={formData.authors}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <Label htmlFor="abstract">Abstract</Label>
          <Textarea
            id="abstract"
            name="abstract"
            value={formData.abstract}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <Label htmlFor="keywords">Keywords</Label>
          <Input
            type="text"
            id="keywords"
            name="keywords"
            value={formData.keywords}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <Label htmlFor="manuscript">Manuscript (PDF)</Label>
          <Input
            type="file"
            id="manuscript"
            name="manuscript"
            onChange={handleFileChange}
            accept=".pdf"
            required
          />
        </div>
        <Button type="submit" className="w-full" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit Research'}
        </Button>
      </form>
      {submitStatus && (
        <div className={`mt-4 p-4 rounded ${submitStatus.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {submitStatus.message}
        </div>
      )}
    </div>
  );
}

export default Submit;