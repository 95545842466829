import React, { useState, useEffect } from 'react';
import { Button } from "../components/ui/button";

export default function Home() {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    setIsLoading(true);
    setError(null);
    try {
      //const response = await fetch('http://localhost:5000/articles');
      const response = await fetch('http://flask-env-final-rr.eba-pbrmzcv3.us-west-2.elasticbeanstalk.com/articles');
      

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setArticles(data);
    } catch (e) {
      setError('Failed to fetch articles. Please try again later.');
      console.error('Error fetching articles:', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReadMore = (article) => {
    setSelectedArticle(article);
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-red-500 mb-4">{error}</p>
        <Button onClick={fetchArticles} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300">
          Retry
        </Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-4xl font-bold mb-12 text-center text-gray-800 relative">
        Latest Publications
     
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {articles.map((article) => (
          <div key={article.id} className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300">
            <img src={article.image_url} alt={article.title} className="w-full h-48 object-cover" />
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{article.title}</h3>
              <p className="text-sm text-blue-600 mb-4">{article.authors}</p>
              <p className="text-sm text-gray-600 mb-4">{article.abstract.substring(0, 150)}...</p>
              <div className="flex justify-between items-center">
                <Button 
                  onClick={() => handleReadMore(article)} 
                  className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  Read More
                </Button>
                <span className="text-sm text-gray-500">Published: {new Date(article.published_date).toLocaleDateString()}</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedArticle && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-8 max-w-4xl w-full max-h-[90vh] overflow-y-auto relative">
            <button 
              onClick={() => setSelectedArticle(null)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <img src={selectedArticle.image_url} alt={selectedArticle.title} className="w-full h-64 object-cover mb-6 rounded" />
            <h2 className="text-3xl font-bold mb-4 text-gray-800">{selectedArticle.title}</h2>
            <p className="text-sm text-blue-600 mb-4">{selectedArticle.authors}</p>
            <h3 className="text-xl font-semibold mb-2 text-gray-700">Abstract</h3>
            <p className="text-gray-600 mb-6">{selectedArticle.abstract}</p>
            <h3 className="text-xl font-semibold mb-2 text-gray-700">Full Article</h3>
            {selectedArticle.manuscript_path ? (
              <iframe
                //src={`http://localhost:5000/uploads/${selectedArticle.manuscript_path}`}
                src={`http://flask-env-final-rr.eba-pbrmzcv3.us-west-2.elasticbeanstalk.com/uploads/${selectedArticle.manuscript_path}`}
                
                className="w-full h-[60vh] border-2 border-gray-300 rounded"
                title="Article PDF"
              />
            ) : (
              <p className="text-gray-600">Full article not available.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}