import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Envirology</h3>
            <p className="text-sm">Accelerating scientific discovery through open collaboration and rapid publication of cutting-edge research.</p>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="text-sm">
              <li className="mb-2"><Link to="/" className="hover:text-gray-300">Home</Link></li>
              <li className="mb-2"><Link to="/about" className="hover:text-gray-300">About</Link></li>
              <li className="mb-2"><Link to="/submit" className="hover:text-gray-300">Submit Research</Link></li>
              <li className="mb-2"><Link to="/browse" className="hover:text-gray-300">Browse Articles</Link></li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
            <p className="text-sm mb-2">Email: info@envirology.org</p>
            <p className="text-sm mb-2">Phone: +1 (925) 750-4322</p>
           </div>          
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p className="text-sm">&copy; {new Date().getFullYear()} Envirology. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;