import React, { useState, useEffect } from 'react';

function About() {
  const [aboutContent, setAboutContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAboutContent = async () => {
      setIsLoading(true);
      try {
        //const response = await fetch('http://localhost:5000/about');
        const response = await fetch('http://flask-env-final-rr.eba-pbrmzcv3.us-west-2.elasticbeanstalk.com/about');
        

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setAboutContent(data);
      } catch (e) {
        setError('Failed to fetch about content. Please try again later.');
        console.error('Error fetching about content:', e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAboutContent();
  }, []);

  if (isLoading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">{error}</div>;
  }

  if (!aboutContent) {
    return <div className="text-center py-10">No content available.</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-900">{aboutContent.title}</h2>
      
      <section className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">Our Mission</h3>
        <p className="text-gray-700">{aboutContent.mission}</p>
      </section>

      <section className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">About Us</h3>
        <p className="text-gray-700">{aboutContent.description}</p>
      </section>

      <section className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">Key Features</h3>
        <ul className="list-disc list-inside text-gray-700">
          {aboutContent.key_features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </section>

      <section className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">Our Team</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {aboutContent.team.map((member, index) => (
            <div key={index} className="border rounded-lg p-4">
              <h4 className="text-xl font-semibold text-gray-800">{member.name}</h4>
              <p className="text-gray-600 mb-2">{member.role}</p>
              <p className="text-gray-700">{member.bio}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default About;